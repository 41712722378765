import {Directive, ElementRef, HostBinding} from '@angular/core';

@Directive({
    selector: '[responsiveFilter]',
})
export class FilterDirective {
    @HostBinding('class.filter-container') isContainer = true;
    constructor(public el: ElementRef) {
    }
}
