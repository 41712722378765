import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent {
    @Input() autoGenerateLabel = false;
    @Input() preserveFragment = true;
    @Input() preserveQueryParams = true;
    @Input() separator: string;
}
