import {ContentChildren, Directive, ElementRef, HostBinding, Input, QueryList} from '@angular/core';
import {DropdownTargetDirective} from './dropdown-target.directive';

@Directive({
    selector: '[responsiveDropdown]',
})
export class ResponsiveDropdownDirective {
    @Input() itemClasses: string;
    @HostBinding('class.responsive-dropdown') isResponsiveDropdown = true;
    @ContentChildren(DropdownTargetDirective, {emitDistinctChangesOnly: false, descendants: true}) dropdowns: QueryList<DropdownTargetDirective>;

    constructor(public el: ElementRef) {}
}

