import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ContentChildren,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewContainerRef,
} from '@angular/core';
import {FilterDirective} from '../filter.directive';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ActionIconComponent} from '../../../../@theme/components/action-icon/action-icon.component';
import {createComponent} from '../../../../utils/component.utils';
import {distinctUntilChanged, filter, switchMap, takeUntil, fromEvent, Observable, of, Subject} from 'rxjs';
import {Breakpoint, breakpoints} from '../../../../@theme/breakpoints';
import {UnsubscribeComponent} from '../../../../@core/fc-component';
import {PageHeaderComponent} from '../page-header/page-header.component';
import {ResponsiveDropdownDirective} from '../responsive-dropdown.directive';
import {FilterModalComponent} from './filter-modal.component';
import {Router, Event as RoutingEvent, NavigationStart} from '@angular/router';

@Component({
    selector: 'app-component-header',
    templateUrl: './component-header.component.html',
    styleUrls: ['./component-header.component.scss'],
})
export class ComponentHeaderComponent extends UnsubscribeComponent implements OnInit, AfterViewInit {
    @ContentChildren(FilterDirective, {
        emitDistinctChangesOnly: false,
        descendants: true,
    }) filters: QueryList<FilterDirective>;
    @ContentChildren(PageHeaderComponent) pageHeaderElements: QueryList<PageHeaderComponent>;
    @ContentChildren(ResponsiveDropdownDirective, {
        emitDistinctChangesOnly: false,
        descendants: true,
    }) dropdowns: QueryList<ResponsiveDropdownDirective>;

    @Input() responsiveFilterFrom: Breakpoint = 'lg';
    @Input() responsiveDropdownFrom: Breakpoint = 'lg';
    @Input() needManualChangeDetection = false;

    registeredFilters = new Array<RegisteredElement>();
    registeredDropdowns = new Array<RegisteredElement>();
    cancelResponsiveDropdownFrom$ = new Subject<void>();
    cancelResponsiveFilterFrom$ = new Subject<void>();
    windowResizeEvent: Observable<Event> = fromEvent(window, 'resize');

    private _filterButtonRef: ComponentRef<ActionIconComponent> = createComponent(ActionIconComponent, this.viewContainerRef);
    private _modalRef: NgbModalRef;

    constructor(private modalService: NgbModal,
                private viewContainerRef: ViewContainerRef,
                private ref: ChangeDetectorRef,
                private router: Router) {
        super();
        this._filterButtonRef.instance.isVisible = false;
    }

    ngOnInit() {
        this.init();
    }

    private init() {
        this.initFilters();
        this.initDropdowns();
    }

    ngAfterViewInit() {
        this.router.events.pipe(
            takeUntil(this.destroy$),
            filter((e: RoutingEvent): e is NavigationStart => e instanceof NavigationStart),
        ).subscribe(() => {
            this._modalRef?.close();
        });
        if (this.responsiveFilterFrom) {
            this.filters.forEach(component => {
                this.registerElement(this.registeredFilters, component.el, component);
            });
            this.filters.changes.pipe(takeUntil(this.destroy$)).subscribe((changes: QueryList<FilterDirective>) => {
                const newElements = changes.filter(component => !this.registeredFilters.find(element =>
                    element.element === component.el
                ));
                newElements.forEach(component => {
                    this.registerElement(this.registeredFilters, component.el, component);
                });
                if (newElements.length) {
                    this.showHideFilterIcon(window.innerWidth < breakpoints[this.responsiveFilterFrom]);
                }
            });
            this.showHideFilterElements(window.innerWidth < breakpoints[this.responsiveFilterFrom]);

            if (!this.pageHeaderElements) return;
            this.pageHeaderElements.changes.pipe(
                takeUntil(this.destroy$),
            ).subscribe((x: QueryList<PageHeaderComponent>) => {
                if (x.length) this._appendFilterBtn();
            });
            this._appendFilterBtn();
        }
        if (this.responsiveDropdownFrom) {
            this.dropdowns.forEach(component => {
                this.registerElement(this.registeredDropdowns, component.el, component);
                component.dropdowns.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
                    this.showHideDropdownButton(window.innerWidth < breakpoints[this.responsiveDropdownFrom]);
                });
            });
            this.dropdowns.changes.pipe(takeUntil(this.destroy$)).subscribe((changes: QueryList<ResponsiveDropdownDirective>) => { // kiemelhetnénk
                const newElements = changes.filter(component => !this.registeredDropdowns.find(element =>
                    element.element === component.el
                ));
                newElements.forEach(component => {
                    this.registerElement(this.registeredDropdowns, component.el, component);
                    component.dropdowns.changes.pipe(takeUntil(this.destroy$)).subscribe(change => {
                        this.showHideDropdownButton(window.innerWidth < breakpoints[this.responsiveDropdownFrom]);
                    });
                });
                if (newElements.length) this.showHideDropdownButton(window.innerWidth < breakpoints[this.responsiveDropdownFrom]);
            });
            this.showHideDropdownButton(window.innerWidth < breakpoints[this.responsiveDropdownFrom]);
        }
    }

    initDropdowns() {
        this.cancelResponsiveDropdownFrom$.next();
        this.windowResizeEvent.pipe(
            takeUntil(this.destroy$),
            takeUntil(this.cancelResponsiveDropdownFrom$),
            switchMap(() => of(window.innerWidth < breakpoints[this.responsiveDropdownFrom])),
            distinctUntilChanged(),
        ).subscribe(isUnderBreakPoint => {
            this.showHideDropdownButton(isUnderBreakPoint);
        });
    }

    initFilters() {
        this.cancelResponsiveFilterFrom$.next();
        this.windowResizeEvent.pipe(
            takeUntil(this.destroy$),
            takeUntil(this.cancelResponsiveFilterFrom$),
            switchMap(() => of(window.innerWidth < breakpoints[this.responsiveFilterFrom])),
            distinctUntilChanged(),
        ).subscribe(isUnderBreakPoint => {
            this._modalRef?.dismiss();
            this.showHideFilterIcon(isUnderBreakPoint);
        });
        this._filterButtonRef.instance.icon = 'slidersH';
        this._filterButtonRef.instance.size = 'xl';
        this._filterButtonRef.location.nativeElement.classList.add('ml-2', 'responsive-filter-btn');
        this._filterButtonRef.location.nativeElement.addEventListener('click', () => {
            this.openModal();
        });
        this._filterButtonRef.instance.isVisible = false;
    }

    private showHideDropdownButton(isUnderBreakPoint: boolean) {
        if (!this.registeredDropdowns.some(dropdown => dropdown.component.dropdowns.length > 0)) {
            return;
        }
        if (isUnderBreakPoint) {
            this.pageHeaderElements.get(0).responsiveDropdown.el.nativeElement.classList.remove('d-none');
            this.registeredDropdowns.forEach(dropdown => {
                dropdown.element.nativeElement.classList.add('m-0');
                dropdown.component.dropdowns.forEach(element => element.el.nativeElement.classList = 'dropdown-item');
                this.pageHeaderElements.get(0).responsiveDropdown.dropdownMenu.nativeElement.prepend(dropdown.element.nativeElement);
            });
        } else {
            this.pageHeaderElements.get(0).responsiveDropdown.el.nativeElement.classList.add('d-none');
            this.registeredDropdowns.forEach(dropdown => {
                dropdown.element.nativeElement.classList.remove('m-0');
                this.copyBackElement(dropdown);
                dropdown.component.dropdowns.forEach(element => element.el.nativeElement.classList =
                    element.extraClasses ?? `${(dropdown.component as ResponsiveDropdownDirective).itemClasses}`
                );
            });
        }
    }

    private showHideFilterIcon(isUnderBreakPoint: boolean) {
        if (!this.pageHeaderElements?.length || !this.registeredFilters.length) return;
        this._filterButtonRef.instance.isVisible = isUnderBreakPoint;
        this.showHideFilterElements(isUnderBreakPoint);
        if (this.needManualChangeDetection) {
            this.ref.markForCheck();
        }
    }

    private showHideFilterElements(isUnderBreakPoint: boolean) {
        if (isUnderBreakPoint) {
            this.registeredFilters.forEach(element => {
                element.element.nativeElement.classList.add('d-none');
            });
        } else {
            this.registeredFilters.forEach(element => {
                this.copyBackElement(element);
                element.element.nativeElement.classList.remove('d-none');
            });
        }
    }

    private registerElement(array: RegisteredElement[], element: ElementRef, component: any) {
        array.push(
            {
                element,
                parentElement: element.nativeElement.parentNode,
                index: Array.from(element.nativeElement.parentNode.childNodes).indexOf(element.nativeElement),
                component,
            }
        );
    }

    private openModal() {
        this._modalRef = this.modalService.open(FilterModalComponent);
        this._modalRef.componentInstance.name = 'FilterModalComponent';
        this.registeredFilters.forEach(element => {
            document.getElementById('appendTarget').appendChild(element.element.nativeElement);
            element.element.nativeElement.classList.remove('d-none');
        });
    }

    private copyBackElement(element: RegisteredElement) {
        element.parentElement.insertBefore(element.element.nativeElement, element.parentElement.childNodes[element.index]);
        element.element.nativeElement.classList.remove('d-none');
    }

    private _appendFilterBtn() {
        if (!this.pageHeaderElements.length) return;
        this.showHideFilterIcon(window.innerWidth < breakpoints[this.responsiveFilterFrom]);
        this.pageHeaderElements.get(0).addon ?
            this.pageHeaderElements.get(0).addon.nativeElement.appendChild(this._filterButtonRef.location.nativeElement) :
            this.pageHeaderElements.get(0).ref.nativeElement.appendChild(this._filterButtonRef.location.nativeElement);
    }
}

interface RegisteredElement {
    element: ElementRef;
    parentElement: Node;
    index: number;
    component: any;
}
