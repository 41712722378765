import {Component, ElementRef, HostBinding, Input, ViewChild} from '@angular/core';
import {Breakpoint} from '../../../../@theme/breakpoints';
import {ResponsiveDropdownComponent} from './responsive-dropdown/responsive-dropdown.component';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
    addon: ElementRef;
    @Input() hasBreadcrumbs = true;
    @Input() addonClasses: string;
    @Input() title: string;
    @Input() titleClasses: string;
    @ViewChild('responsiveDropdown') responsiveDropdown: ResponsiveDropdownComponent;

    @HostBinding('class.justify-content-end') get isWithoutBreadcrumbs() {
        return !this.hasBreadcrumbs;
    }

    @Input('breakAddonToNewLineFrom') set _breakAddonToNewLineFromClass(value: Breakpoint) {
        this.ref.nativeElement.classList.add(`break-addon-to-new-line-${value}`);
    }

    @HostBinding('class.section-head')
    @Input() sectionHeader = false;

    @HostBinding('class.page-heading') get isPageHeading() {
        return !this.sectionHeader;
    }
    constructor(public ref: ElementRef) {
        this.addon = ref;
    }
}
