import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'app-responsive-dropdown',
    templateUrl: './responsive-dropdown.component.html',
    styleUrls: ['./responsive-dropdown.component.scss'],
})
export class ResponsiveDropdownComponent implements OnInit {
    @ViewChild('dropdownMenu') dropdownMenu: ElementRef;
    @ViewChild('dropdownToggle') dropdownToggle: ElementRef;

    constructor(public el: ElementRef) {
    }

    ngOnInit(): void {
    }
}
