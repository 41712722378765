import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[dropdownTarget]',
})
export class DropdownTargetDirective {
    @Input() extraClasses: string;
    constructor(public el: ElementRef) {
    }
}
